body,
html {
  font-family: "Montserrat", sans-serif;
  margin: 0;
}
* {
  background-color: #faf9f6;
}
.App {
  display: grid;
  text-align: center;
  place-items: left;
}
.big {
  font-size: 50px;
}
.pancake {
  display: grid;
  grid-template-rows: auto 1fr auto;
  place-items: center;
  height: 100vh;
}
div {
  display: grid;
  align-items: left;
}
.nav {
  display: flex;
  justify-content: center;
  place-items: center;
  width: 100vw;
  background: #efdcf9;
}
#basic {
  padding: 0.3rem;
  color: black;
  text-decoration: none;
  font-size: 3vh;
  background: #efdcf9;
}
a {
  display: inline-flex;
  margin-left: 1.5vw;
  margin-right: 1.5vw;
  transition: transform 0.5s ease;
}
a:hover {
  transform: translateY(-3px);
}
#footer:hover {
  color: magenta;
}
.arr {
  display: grid;
  align-items: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}
.error {
  color: red;
  background: lightgrey;
  font-size: 20px;
  border-style: solid;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

.confirm {
  color: green;
  background: lightgrey;
  font-size: 20px;
  border-style: solid;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}
#notif {
  max-width: 20vw;
}
.Footer {
  display: flex;
  width: 100vw;
  justify-content: center;
  place-items: center;
  background: #efdcf9;
}

#footer {
  padding: 1.5rem;
  font-size: 2vh;
  text-decoration: none;
  color: black;
  background: #efdcf9;
}
